export type ShopSortItem = {
	value: string;
	label: string;
};

export const PRODUCTS_PER_PAGE = 16;
export const BLOGS_PER_PAGE = 12;

export const SHOP_SORT_ITEMS: ShopSortItem[] = [
	{
		value: 'newest',
		label: 'Newest',
	},
	{
		value: 'priceASC',
		label: 'Price-Low to high',
	},
	{
		value: 'priceDESC',
		label: 'Price-High to low',
	},
	{
		value: 'discounted',
		label: 'Discounted',
	},
	{
		value: 'topSales',
		label: 'Best sellers',
	},
];

